<template>
  <section>
    <b-card class="lightColor1 loginpage text-center mt-5 mb-5">
      <b-img src="image/LogIn.svg" alt="Login" class="login-image"></b-img>
      <p
        class="text-dark mt-1 font-size-25 font-weight text-capitalize"
        v-if="$route.path == '/login'"
      >
        Log In
      </p>
      <p
        class="text-dark mt-1 font-size-25 font-weight text-capitalize"
        v-if="$route.path == '/signup'"
      >
        Sign Up
      </p>
      <div class="checkbox text-center d-flex flex-row justify-content-center">
        <b-checkbox
          class="mt-1"
          size="md"
          id="termscondition"
          value="accepted"
          v-model="accepted"
          @change="enableButton"
        ></b-checkbox>
        <label
          class="ml-2 mt-1 font-size-12 text-capitalize"
          for="termscondition"
          >By clicking sign in you are agree with our<span
            ><router-link to="/termscondition">
              Terms & conditions |</router-link
            ><router-link to="/privacypolicy">
              privacy policy</router-link
            ></span
          ></label
        >
      </div>
      <b-button
        class="mr-4 button-bg-dark font-size-20 mt-5"
        :disabled="disabled"
        @click="linkedin"
        v-if="$route.path == '/login'"
        >Login In With Linkedin</b-button
      >
      <b-button
        class="mr-4 button-bg-dark font-size-20 mt-5"
        :disabled="disabled"
        @click="linkedin"
        v-if="$route.path == '/signup'"
        >Sign Up With Linkedin</b-button
      >
      <!-- <b-button
        variant="primary"
        :disabled="disabled"
        class="mt-2"
        @click="linkedin"
        >Sign in with linkedin</b-button
      > -->
    </b-card>
  </section>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Login",

  data: function () {
    return {
      disabled: true,
      accepted: 1,
    };
  },

  mounted() {
    setTimeout(() => {
      if (this.$store.getters.isAuthenticated) {
        this.$router.push("/");
      }
    }, 1000);
  },

  methods: {
    ...mapActions(["sendLoginWithLinkedIn"]),

    linkedin() {
      this.sendLoginWithLinkedIn().then((response) => {
        window.location.href = response.data.data;
      });
    },
    enableButton() {
      this.disabled = !this.disabled;
    },
  },
};
</script>
